// @codekit-prepend "../node_modules/jquery/dist/jquery.min.js";
// @codekit-prepend "../bower_components/fancybox/dist/jquery.fancybox.min.js";
// @codekit-prepend "../node_modules/fitvids.js/jquery.fitvids.js";
// @codekit-prepend "../node_modules/stickybits/dist/stickybits.min.js";
// @codekit-prepend "../node_modules/lazysizes/lazysizes.min.js";




$(document).ready(function() {

	// the off-canvas buttons

	var transform = $('.transformer');

	$('#men_but').on('click',function() {
		console.log("click");
		$(this).toggleClass('active');
		transform.toggleClass('is-open');
	});

	 // fancybox

	$('[data-fancybox="pix"]').fancybox({
  		loop : true,
  		infobar : true,
  		smallBtn : true,
  		toolbar : false,
		buttons : ['zoom','close']
		});

 	// fix video insert

	$("#vid").fitVids();

var sh = document.querySelectorAll('.banner')+ 23;
var sbP =  $('.title-box');
var abP =  $('#stickybit-parent');

stickybits(abP, {
		//useFixed: true,
		stickyBitStickyOffset: 143,
		useStickyClasses: true

	});

});

